<template>
  <v-card class="pa-2">
    <div class="d-flex align-center mb-1">
      <v-subheader>{{ title }}</v-subheader>
      <v-spacer />
      <v-text-field
        dense
        hide-details
        filled
        prepend-inner-icon="mdi-magnify"
        v-model="search"
      />
      <v-btn
        color="primary"
        class="ml-1"
        text
        @click.stop="$emit('exportar', value, 'deudaPorCliente')"
        icon
      >
        <v-icon>mdi-download</v-icon>
      </v-btn>
    </div>

    <v-data-table
      :search="search"
      :items="value"
      :headers="[
        { text: 'Cliente', value: 'denominacion' },
        { text: 'Deuda Total', value: 'deuda' },
        { text: 'Deuda Impagada', value: 'deudaImp' },
        { text: 'Giro Pendiente', value: 'deudaApl' },
        { text: 'Deuda Fraccionada', value: 'deudaFra' },
      ]"
    >
      <template v-slot:item.denominacion="{ item }">
        <!-- <router-link :to="`/clientes/detalles?idCliente=${item.IdCliente}`" class="text-button text-decoration-none secondary--text" >{{item.denominacion}}</router-link> -->
        <v-btn
          text
          color="secondary"
          small
          :to="`/clientes/detalles?idCliente=${item.IdCliente}`"
          >{{ item.denominacion }}</v-btn
        >
      </template>

      <template v-slot:item.deudaImp="{ item }">
        <v-btn small text>
          <v-icon small left :color="getSituacionIcon('Impagado').color"
            >mdi-currency-eur-off</v-icon
          >
          {{ item.deudaImp }} €</v-btn
        >
      </template>

      <template v-slot:item.deudaApl="{ item }">
        <v-btn small text>
          <v-icon small left :color="getSituacionIcon('Pendiente').color"
            >mdi-timer-sand</v-icon
          >
          {{ item.deudaApl }} €</v-btn
        >
      </template>

      <template v-slot:item.deudaFra="{ item }">
        <v-btn small text>
          <v-icon
            small
            left
            :color="getSituacionIcon('Fraccionado 2 Pagos').color"
            >mdi-division</v-icon
          >
          {{ item.deudaFra }} €</v-btn
        >
      </template>

      <template v-slot:item.deuda="{ item }">
        <v-btn
          small
          :to="`/facturacion?Identificador=${item.Identificador}`"
          text
        >
          <v-icon small left>mdi-equal</v-icon>
          {{ item.deuda }} €</v-btn
        >
      </template>
    </v-data-table>

  </v-card>
</template>

<script>
import { getSituacionIcon } from "@/utils/index.js";

export default {
  props: {
    value: { type: Array, optional: false },
    alertInfo: { type: Boolean, default: false },
    title: { type: String, default: "Deuda por cliente" },
  },
  data() {
    return {
      search: null,
    };
  },
  methods: {
    getSituacionIcon,
  },
};
</script>

<style scoped>
.v-data-iterator >>> .v-data-footer__select {
  display: none !important;
}
.v-data-iterator >>> .v-data-footer__pagination {
  margin-left: auto !important;
}
</style>
