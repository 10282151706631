var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-2"},[_c('div',{staticClass:"d-flex align-center mb-1"},[_c('v-subheader',[_vm._v(_vm._s(_vm.title))]),_c('v-spacer'),_c('v-text-field',{attrs:{"dense":"","hide-details":"","filled":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"ml-1",attrs:{"color":"primary","text":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('exportar', _vm.value, 'deudaPorCliente')}}},[_c('v-icon',[_vm._v("mdi-download")])],1)],1),_c('v-data-table',{attrs:{"search":_vm.search,"items":_vm.value,"headers":[
      { text: 'Cliente', value: 'denominacion' },
      { text: 'Deuda Total', value: 'deuda' },
      { text: 'Deuda Impagada', value: 'deudaImp' },
      { text: 'Giro Pendiente', value: 'deudaApl' },
      { text: 'Deuda Fraccionada', value: 'deudaFra' } ]},scopedSlots:_vm._u([{key:"item.denominacion",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":"secondary","small":"","to":("/clientes/detalles?idCliente=" + (item.IdCliente))}},[_vm._v(_vm._s(item.denominacion))])]}},{key:"item.deudaImp",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","text":""}},[_c('v-icon',{attrs:{"small":"","left":"","color":_vm.getSituacionIcon('Impagado').color}},[_vm._v("mdi-currency-eur-off")]),_vm._v(" "+_vm._s(item.deudaImp)+" €")],1)]}},{key:"item.deudaApl",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","text":""}},[_c('v-icon',{attrs:{"small":"","left":"","color":_vm.getSituacionIcon('Pendiente').color}},[_vm._v("mdi-timer-sand")]),_vm._v(" "+_vm._s(item.deudaApl)+" €")],1)]}},{key:"item.deudaFra",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","text":""}},[_c('v-icon',{attrs:{"small":"","left":"","color":_vm.getSituacionIcon('Fraccionado 2 Pagos').color}},[_vm._v("mdi-division")]),_vm._v(" "+_vm._s(item.deudaFra)+" €")],1)]}},{key:"item.deuda",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","to":("/facturacion?Identificador=" + (item.Identificador)),"text":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-equal")]),_vm._v(" "+_vm._s(item.deuda)+" €")],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }